import React from 'react';
import { graphql } from 'gatsby';

import Banner from '../../components/Banner';
import Contact from '../../components/Contact';
import Layout from '../../components/Layout/Layout';
import Container from '../../components/Container';
import Seo from '../../components/seo';

import * as styles from './index.module.css';

const SupportPage = (props) => {
  const { storeWebsiteData } = props.data;
  const {
    store_description,
    store_logo
  } = storeWebsiteData.store_primary_settings.store_ui;

  return (
    <Layout disablePaddingBottom>
      <Seo
        title="Contact"
        description={store_description}
        banner={store_logo || ""}
        pathname="/contact/"
      />
      <div className={styles.root}>
        <Banner
          maxWidth={'650px'}
          name={'Contact'}
          bgColor={'var(--standard-light-grey)'}
        />

        <div className={styles.pageContainer}>
          <Container size={'large'} spacing={'min'}>
            <div className={`${styles.content} ${styles.show}`}>
              <Contact
                data={storeWebsiteData?.store_primary_settings?.store_ui}
                socialMedia={storeWebsiteData?.store_primary_settings?.store_social_media}
              />
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Contact {
    storeWebsiteData: zzStoreWebsiteDetails {
      id
      store_primary_settings {
        store_social_media {
          store_instagram
          store_facebook
          store_twitter
          store_tiktok
          store_linkedin
          store_youtube
          store_snapchat
        }
        store_ui {
          store_description
          store_phone 
          store_contact_email
          store_address
          store_logo
        }
      }
    }
  }
`;

export default SupportPage;
