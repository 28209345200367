import React from 'react';

import * as styles from './Contact.module.css';

const Contact = (props) => {
  return (
    <div className={styles.root}>
      <div>
        {
          !!props.data?.store_phone && (
            <div className={styles.section}>
              <h4>Numéro de téléphone</h4>
              <a href={`tel://${props.data?.store_phone}`}>
                {props.data?.store_phone}
              </a>
            </div>
          )
        }
        {
          !!props.data?.store_address && (
            <div className={styles.section}>
              <h4>Adresse</h4>
              <p>{props.data?.store_address}</p>
            </div>
          )
        }
        {
          !!props.data?.store_contact_email && (
            <div className={styles.section}>
              <h4>Email</h4>
              <a href={`mailto://${props.data?.store_contact_email}`}>
                {props.data?.store_contact_email}
              </a>
            </div>
          )
        }

      </div>
      <div>
        <div className={styles.section}>
          <h4>Réseaux sociaux</h4>
          {
            Object.keys(props.socialMedia || {}).map((socialItem) => {
              const link = props.socialMedia?.[socialItem];
              const linkLabel = socialItem.substring("store_".length);

              if (!!link) {
                return (
                  <div key={socialItem} className={styles.socialLink}>
                    <a href={`${props.socialMedia?.[socialItem]}`}>
                      {linkLabel}
                    </a>
                  </div>
                );
              }
              return null;
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Contact;
